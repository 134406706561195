*{
    margin: 0;
    padding: 0;
}

.landing {
    margin-top: -5vh;
    margin-bottom: 5vh;
}

.title-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 58vh;
    padding: 32px;
}

.title {
    color: white;
    font-size: 64px;
    line-height: 60px;
    /*border: 2px solid white;*/
    /*border-radius: 25px;*/
    /*padding: 25px;*/
    /*backdrop-filter: blur(8px);*/
}

.sub-title {
    position: relative;
    height: 40px;
    margin: 0;
    color: white;
    font-size: 32px;
    overflow: hidden;
}

.text-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transform: translateY(0);
    animation: slideUp 6s infinite;
}

.text-item:nth-child(1) {
    transform: translateY(0);
    animation-delay: -2s;
}

.text-item:nth-child(2) {
    transform: translateY(100%);
    animation-delay: 0s;
}

.text-item:nth-child(3) {
    transform: translateY(100%);
    animation-delay: 2s;
}

@keyframes slideUp {
    0%, 33.33% {
        transform: translateY(100%);
    }
    36.66%, 66.66% {
        transform: translateY(0);
    }
    69.99%, 100% {
        transform: translateY(-100%);
    }
}

/* Style all font awesome icons */
.fa {
    color: white;
    padding: 13px;
    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    border: 1px solid white;
    margin-left: 12px;
}

/* Add a hover effect if you want */
.fa:hover {
    opacity: 0.5;
}

section{
    position: relative;
    width: 100%;
    height: 85vh;
    background: #6262ac;
    overflow: hidden;
}
section .air{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(https://1.bp.blogspot.com/-xQUc-TovqDk/XdxogmMqIRI/AAAAAAAACvI/AizpnE509UMGBcTiLJ58BC6iViPYGYQfQCLcBGAsYHQ/s1600/wave.png);
    background-size: 1000px 100px
}
section .air.air1{
    animation: wave 30s linear infinite;
    z-index: 1000;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
}
section .air.air2{
    animation: wave2 15s linear infinite;
    z-index: 999;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
}
section .air.air3{
    animation: wave 30s linear infinite;
    z-index: 998;
    opacity: 0.2;
    animation-delay: -2s;
    bottom: 15px;
}
section .air.air4{
    animation: wave2 5s linear infinite;
    z-index: 997;
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 20px;
}
@keyframes wave{
    0%{
        background-position-x: 0px;
    }
    100%{
        background-position-x: 1000px;
    }
}
@keyframes wave2{
    0%{
        background-position-x: 0px;
    }
    100%{
        background-position-x: -1000px;
    }
}

@media (max-width: 320px) {
    .text-item:nth-child(1) {
        animation: none;
    }

    .text-item:nth-child(2) {
        display: none;
    }

    .text-item:nth-child(3) {
        display: none;
    }
}
