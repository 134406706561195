.contact-form {
    color: #6262ac;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    padding: 12px;
    max-width: 1200px; /* Adjust this value to your desired maximum width */
    /* This will center the content */
    margin: -8px auto 0;
}

.form {
    color: #6262ac;
    display: grid;
    place-items: center;
    padding: 32px 16px;
    border-radius: 25px;
    background: #e3e3f1;;
    max-width: 500px;
    height: 325px;
    width: 75vw;
}

.thanks-message {
    color: #6262ac;
    display: grid;
    place-items: center;
    padding: 32px 16px;
    border-radius: 25px;
    background: #e3e3f1;;
    max-width: 500px;
    height: 325px;
    width: 75vw;
    text-align: center;
}

@media (min-width: 768px) {
    .thanks-message strong {
        margin: 32px;
    }
}

.form label {
    color: #444486;
    width: 90%;
    text-align: left;
    font-weight: bold;
}

.form input {
    margin-top: 6px;
    margin-bottom: 16px;
    width: 90%;
    border: 1px solid #444486;
    border-radius: 5px;
    padding: 4px;
}

.form textarea {
    margin-top: 6px;
    margin-bottom: 16px;
    width: 90%;
    height: 100px;
    border: 1px solid #444486;
    border-radius: 5px;
    resize: none;
    padding: 4px;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: -16px;
}

.submit-button {
    margin-bottom: -12px !important;
    width: 100px !important;
    border: 2px solid #6262ac !important;
    border-radius: 5px !important;
    background-color: #e3e3f1 !important;
    color: #6262ac !important;
    font-weight: bold;
    padding: 8px 15px;
}

.submit-button:hover {
    color: #e3e3f1 !important;
    background-color: #6262ac !important;
}
