.projects-wrapper {
    background: white;
    height: auto;
}

.projects {
    color: #6262ac;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    max-width: 1200px; /* Adjust this value to your desired maximum width */
    margin: 0 auto; /* This will center the content */
}

.project-heading {
    color: #6262ac;
    display: grid;
    place-items: center;
    margin-bottom: 6px;
}

.project-content{
    width: 100%;
    color: #6262ac;
    display: grid;
    place-items: center;
}

.project-item {
    color: #444486 !important;
    max-width: 500px;
    width: 75vw;
    margin-top: -5px;
    margin-bottom: 32px;
    padding: 24px;
    border-radius: 25px;
    background: #e3e3f1;
    text-align: left;
}

.project-layout {
    place-items: center;
    width: 100%;
    max-height: 500px;
}

.desc-and-image {
    display: flex;
    align-items: center;
}

.desc-and-image img {
    width: 100px;
    height: 100px;
    border: 5px solid #6262ac;
    border-radius: 5%;
}

.desc-and-image p {
    margin-right: 20px;
}

.icons-and-link {
    margin-bottom: -19px;
}

.icons-and-link::after {
    content: "";
    display: table;
    clear: both;
}

.icons-and-link button {
    float: right;
    width: 110px;
    padding: 8px 15px;
    border: 2px solid #6262ac;
    border-radius: 5px;
    background-color: #e3e3f1;
    color: #6262ac;
    cursor: pointer;
    transition: background-color 0.3s ease;
    transform: translateY(17px);
}

.coming-soon {
    float: right;
    width: 110px;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    background-color: #e3e3f1;
    color: #6262ac;
    cursor: pointer;
    transition: background-color 0.3s ease;
    transform: translateY(17px) translateX(18px);
}

.icons-and-link button:hover {
    color: #e3e3f1 !important;
    background-color: #6262ac !important;
}

/* Mobile view */
@media (max-width: 768px) {
    .desc-and-image {
        display: block; /* This will stack the children */
    }

    .desc-and-image p {
        margin-right: 0; /* Remove the margin on mobile */
    }

    .desc-and-image img {
        display: block; /* Make the image a block element */
        /* Center the image horizontally */
        margin: 12px auto;
    }
}


@media (min-width: 768px) {
    .projects {
        grid-template-columns: 1fr 1fr;
    }

    .project-item {
        width: 40vw;
    }
}



