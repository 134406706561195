.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.return-text {
  font-size: 24px;
}

.return-text > i {
  margin-left: 12px;
  margin-right: 12px;
}

.footer {
  color: #6262ac !important;
  padding: 20px;
  text-align: center;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
  height: 5vh;
}

.footer-arrow {
  font-size: 40px;
  font-weight: bold;
  animation: MoveUpDown 3s linear infinite;
}

.arrow {
  border: solid #6262ac;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 6px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
}

.header {
  color: white;
  padding: 20px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1001;
  background: rgb(98,98,172);
  /*background: linear-gradient(180deg, rgba(98,98,172,1) 0%, rgba(98,98,172,1) 80%, rgba(255,255,255,0) 100%);*/
  background: linear-gradient(rgba(98,98,172,1) 80%, rgba(255,255,255,0));

}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-buttons {
  margin-top: -17px;
  display: flex;
  gap: 10px;
}

.header-button {
  width: 100px;
  padding: 8px 15px;
  border: 2px solid white;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.header-button:hover {
  background-color: #4559a0;
}

.body {
  flex: 1;
  transform: translateY(-1px);
}

.icon-title-container {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
}

.icon {
  margin-top: -8px;
  margin-bottom: 8px;
  width: 50px;
  transition: all 0.3s ease;
  border: 2px solid white;
  border-radius: 25px;
}

.scrolled .icon {
  width: 30px;
}

.title {
  font-size: 24px;
  transition: all 0.3s ease;
}

.scrolled .title {
  font-size: 18px;
}

.subtext {
  margin-top: 10px;
  transition: opacity 0.3s ease;
}

.scrolled .subtext {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 0;
}
