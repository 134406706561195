.body {
	color: #6262ac;
	display: grid;
	grid-template-columns: 1fr;
	place-items: center;
	height: 100vh;
	padding: 16px;
	max-width: 1200px; /* Adjust this value to your desired maximum width */
	margin: 0 auto; /* This will center the content */
}

.body-content {
	color: #6262ac;
	display: grid;
	place-items: center;
	padding: 16px;
	margin-top: 64px;
}

.divider {
	border: 1px solid #6262ac;
	width: 50px;
	margin-top: 6px;
	margin-bottom: 24px;
}

.bullet {
	background-color: #6262ac;
	height: 100%;
	width: 100%;
	border-radius: 100%;
}

.info-block {
	color: #444486 !important;
	max-width: 500px;
	margin-top: -5px;
	margin-bottom: 32px;
	padding: 24px;
	border-radius: 25px;
	background: #e3e3f1;
	text-align: left;
}

/* Style all font awesome icons */
.fab {
	background: #e3e3f1;
	color: #6262ac;
	padding: 6px;
	font-size: 40px;
	width: 40px;
	text-align: center;
	text-decoration: none;
	border-radius: 5px;
	margin: 6px;
}

/* Add a hover effect if you want */
.fab:hover {
	opacity: 0.5;
}

.history-heading {
	color: #6262ac;
	display: grid;
	place-items: center;
	margin-bottom: 6px;


}

@media (min-width: 768px) {
	.body {
		grid-template-columns: 1fr 1fr;
	}

	.fab {
		margin: 12px;
	}
}
